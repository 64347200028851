.footer {
  width: 100%;
  background: rgba(255, 136, 136, 1);
  display: flex;
  gap: 0.625rem;
  /* margin-top: 80px; */
  padding-top: 1.75rem;
  padding-right: 3.0625rem;
  padding-bottom: 1.75rem;
  padding-left: 3.0625rem;
}

.footerBody {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 2.1875rem;
}

.menu {
  display: flex;
  justify-content: space-between;
  position: relative;
  z-index: 50000;
}

.allLists {
  /* border: 2px solid yellow; */
  display: flex;
  justify-content: space-between;
  gap: 6.75rem;
  width: 100%;
}
.contact {
  display: none;
  max-width: 21.55rem;
}
.title {
  font-family: Cairo;
  font-weight: 600;
  font-size: 0.875rem;
  line-height: 1.64rem;
  letter-spacing: 0%;
  color: rgba(255, 255, 255, 1);
}
.link {
  font-family: Cairo;
  font-weight: 400;
  font-size: 0.849375rem;
  line-height: 1.27375rem;
  letter-spacing: 0%;
  color: rgba(255, 255, 255, 1) !important;
}
.break {
  border-bottom: 2px solid rgba(255, 255, 255, 1);
  width: 90%;
  margin: auto;
}
.copyRight {
  display: flex;
  justify-content: center;
  width: 100%;
}
.copyRightContent {
  display: flex;
  justify-content: space-between;
  align-items: end;
  gap: 0.5rem;
}
.copyRightContent p {
  font-family: Cairo;
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  letter-spacing: 0%;
  color: rgba(11, 82, 67, 1);
}

.footerlist {
  display: flex;
  justify-content: space-between;
  width: 100%;
}
.footerlist ul {
  list-style: none; /* Removes default bullets */
  padding: 0;
  margin: 0;
}

ul li {
  position: relative;
  padding-left: 1.5rem; /* Space for custom bullet */
  margin-bottom: 0.5rem;
  font-size: 1rem;
  color: #333; /* Text color */
  cursor: pointer;
}

.contact h1 {
  font-family: Cairo;
  font-weight: 600;
  font-size: 1.25125rem;
  line-height: 2.345rem;
  letter-spacing: 0%;
  text-align: center;
  color: rgba(255, 255, 255, 1);
}

.message {
  position: relative;
  cursor: pointer;
  width: 100%;
  height: 44.7px;
  gap: 138.48px;
  padding-top: 6.67px;
  padding-right: 13.35px;
  padding-bottom: 6.67px;
  padding-left: 13.35px;
  border-radius: 9px;
  color: rgba(255, 255, 255, 1);
  border: none;
  color: black;
}

.message::placeholder {
  font-family: Cairo;
  font-weight: 500;
  font-size: 13.35px;
  line-height: 20.02px;
  letter-spacing: 0%;
  text-align: right;

  color: rgba(185, 185, 185, 1);
}
.sendBtn {
  position: absolute;
  left: 5px;
  top: 5px;
  width: 54.7px;
  height: 31.35px;
  padding-top: 6.67px;
  padding-right: 13.35px;
  padding-bottom: 6.67px;
  padding-left: 13.35px;
  border-radius: 6.67px;
  background: rgba(215, 26, 30, 0.79);
  color: rgba(255, 255, 255, 1);
  border: none;
  font-family: Cairo;
  font-weight: 600;
  font-size: 11.68px;
  line-height: 17.52px;
  letter-spacing: 0%;
  text-align: center;
  cursor: pointer;
}
@media (min-width: 800px) {
  .contact {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 0.834375;
    /* border: 2px dotted white; */
    /* border-radius: 50px; */
    /* background-color: whitesmoke; */
  }

  .allLists {
    max-width: 50%;
  }

  .title {
    font-size: 1.1325rem;
    line-height: 2.121875rem;
  }
}

a {
  all: unset;
  cursor: pointer;
}
