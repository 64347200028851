.header {
  background: rgba(255, 255, 255, 1);
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  width: 100%;
  /* height: 80; */
  /* gap: 86px; */

  display: flex;
  justify-content: space-between;
  border-radius: 0.25rem;
  padding-top: 0.875rem;
  padding-bottom: 0.875rem;
  padding-right: 1rem;
  padding-left: 1rem;
  /* border: 2px solid red; */
}

.headerContent {
  width: 70%;
  /* height: 80; */
  /* border: 2px solid green; */
  display: flex;
  /* gap: 86px; */
  padding-left: 2rem;
  justify-content: space-between;
  align-items: center;
}

.link {
  display: flex;
  padding: 0.625rem;
  gap: 0.625rem;
}
.linkText {
  font-family: Cairo;
  font-weight: 500;
  font-size: 1.25rem;
  line-height: 2.3425rem;
  color: rgba(0, 0, 0, 1);
}

.navbarSupportedContent {
  /* border: 2px dotted black; */
  width: 460px;
}
.account {
  display: flex;
  /* border: 2px solid red; */
  justify-content: space-around;
  /* gap: 66px; */
}
.signup {
  gap: 0.625rem;
  padding: 0.625rem;
  border: none;
  border-bottom: 0.0625rem solid rgba(0, 0, 0, 1);
  cursor: pointer;
}

.signupText {
  font-family: Cairo;
  font-weight: 400;
  font-size: 1.25rem;
  line-height: 2.3425rem;
  letter-spacing: 0%;
  color: rgba(0, 0, 0, 1);
}
.createAccount {
  display: flex;
  background: rgba(215, 26, 30, 0.79);
  color: rgba(255, 255, 255, 1);
  border: none;
  padding: 0.625rem 2.5rem;
  border-radius: 0.625rem;
  gap: 0.625rem;
  cursor: pointer;

  font-family: Cairo;
  font-weight: 400;
  font-size: 1.25rem;
  line-height: 2.3425rem;
  letter-spacing: 0%;
  text-align: center;
}
