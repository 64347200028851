@import url("https://fonts.googleapis.com/css?family=Cairo");

.input-verification-code {
  direction: ltr !important;
}

* {
  direction: rtl !important;
  font-family: "Cairo", sans-serif;
}
*:focus {
  outline: none;
  box-shadow: none !important;
}

body {
  direction: rtl !important;
  background-color: rgb(255, 255, 255) !important;
}

/* Start Navbar CSS */
.navbar {
  background-color: rgb(255, 255, 255) !important;
  z-index: 99999999;
  width: 100%;
  box-shadow: 0 0 8px 0 rgba(0, 0, 0, 0.25);
}

.nav-search {
  width: 22px;
  height: 22px;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  gap: 4px;
  margin-right: 9px;
  padding: 0;
  object-fit: contain;
  border-radius: 500px;
}
.nav-search-bar {
  outline: none !important;
  border: none !important;
  margin-right: 30px;
}

.nav-logo {
  width: 109px;
  height: 52px;
  margin: 13px 0 0px 0px;
  object-fit: contain;
}
.nav-main {
  width: 54px;
  height: 30px;
  text-decoration: none;
  margin-right: 38px;
  font-size: 16px;
  font-weight: 500;
  letter-spacing: 0.32px;
  text-align: center;
  color: #890405 !important;
}
.nav-element {
  height: 30px;
  text-decoration: none;
  margin-right: 30px;
  font-size: 16px !important;
  font-weight: 500;
  letter-spacing: 0.32px !important;
  text-align: center;
  text-wrap: nowrap;
  color: #454545 !important;
}
.nav-element :active {
  color: #df1f1f !important;
}

.first-btn {
  font-size: 1em;
  justify-content: center;
  align-items: center;
  padding: 9px 0px;

  color: white !important;
  background-color: #890405 !important;
  border-radius: 44px;
}

.new-btn {
  font-size: 1em;
  justify-content: center;
  align-items: center;
  padding: 9px 18px;
  margin-right: 12px;
  color: white !important;
  background-color: #890405 !important;
  border-radius: 44px;
  border: none;
}

.add-two-button1 .first-btn {
  width: 88px;
  height: 30px;
  flex-grow: 0;
  color: white;
}

.add-two-button .second-btn {
  width: 130px;
  height: 30px;
  flex-grow: 0;
  font-size: 16px;
  border: none;
  background-color: rgba(00, 00, 00, 0) !important;
  letter-spacing: 0.32px !important;
  text-align: center;
  color: #890405 !important;
  display: inline-block;
}
.second-botton {
  width: 130px;
  height: 30px;
  flex-grow: 0;
  font-size: 16px;
  border: none;
  background-color: rgba(00, 00, 00, 0) !important;
  letter-spacing: 0.32px !important;
  text-align: center;
  color: #890405 !important;
  display: inline-block;
}

@media (max-width: 767px) {
  .edit-size-input {
    width: 100% !important;
  }

  .add-two-button {
    margin-top: 15px;
    margin-bottom: 8px;
  }
}

@media (min-width: 1025px) {
  .edit-size-input {
    width: 400px !important;
  }
}

@media (min-width: 768px) {
  .add-two-button {
    margin-top: 15px;
    margin-bottom: 8px;
    margin-left: 15px;
  }
}

.dropdown-menu.show {
  display: flex;
  background-color: #ffffffff !important;
  opacity: 90%;
}

.dropdown-menu[data-bs-popper] {
  top: 142% !important;
  left: -160px !important;
  margin-top: 0.125rem !important;
  margin-bottom: 1.125rem !important;
}

.dropdown-menu {
  min-width: 30rem;
}

@media (max-width: 767px) {
  .dropdown-menu.show {
    display: unset;
    background-color: black !important;
    text-align: center;
  }
}

/* .dropdown-item &:active {
    color: rgb(155, 37, 37) !important;
} */

.edit-btn-drop {
  border: 1px solid white;
  color: white;
  margin-right: 5px;
  margin-top: 5px;
}

.dropdown-item:hover {
  color: rgb(211, 19, 19) !important;
  background-color: #e9ecef;
}

/* End Navbar CSS */
/*Start Settings CSS*/

.setting .dropdown-menu[data-bs-popper] {
  top: 70px !important;
  left: 0 !important;
  margin-top: 0 !important;
  margin-bottom: 0 !important;
}

.setting .dropdown-menu.show {
  display: flex;
  width: 250px;
  flex-direction: column;
}

.notification .dropdown-menu.show {
  display: flex;
  width: 350px;
  flex-direction: column;
}

.setting .dropdown-menu {
  min-width: 0% !important;
}

/*End Settings CSS*/
/*Start Mian Header CSS*/
.header {
  background: #f9eaeb;
  background-image: url("../images/headerPattern.png");
  background-repeat: no-repeat;
  padding-top: 100px;
  border-bottom-left-radius: 89px;
  border-bottom-right-radius: 89px;
  justify-content: center;
}
.main-header-content {
  background: #f5f3f4;
  width: 587px !important;
  background-size: cover;
  background-repeat: no-repeat;
  min-height: 100vh;
  width: max-content;
  border-bottom-left-radius: 89px;
  border-bottom-right-radius: 89px;
}
.main-header-student {
  width: 722px;
}
.main-header-icon {
  position: absolute;
  top: 260px;
  left: 131px;
  opacity: 90%;
  width: 200px;
  animation: updown 4s ease infinite;
}
@keyframes updown {
  0% {
    transform: translateY(-10%);
  }

  50% {
    transform: translateY(10%);
  }

  100% {
    transform: translateY(-10%);
  }
}
.main-header-scroll {
  justify-content: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: fit-content;
  margin-top: -4.5em;
  animation: updown 4s ease infinite;
  background: none;
  border: none;
}

.red-gradient {
  color: linear-gradient(180deg, #ba142d 0%, #f9eaeb 100%);
}

.header-card {
  width: 230px;
  height: 90px;
  background-color: rgba(255, 255, 255, 0.8);
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
  border-radius: 20px;
  color: #545567;
  position: absolute;
  top: 572px;
  left: 404px;
}
.students-icon {
  width: 50px;
  height: 50px;
  margin-left: 15px;
}

.student-icon-container {
  animation: updown 4s ease infinite;
}

.header-main-title {
  align-self: center;
  font-size: 3em;
  color: #b71014;
  font-weight: 700;
  letter-spacing: 0.02em;
}

.edit-title-header-text {
  display: flex;
  flex-direction: column;
  align-content: flex-start;
  justify-content: center;
  align-items: center;
  text-align: center;
  padding-top: 80px;
}

.edit-title-header-image-teacher {
  display: flex;
  flex-direction: column;
  align-content: flex-start;
  justify-content: center;
  align-items: center;
}
.edit-title-header-text h1 {
  color: #b71014 !important;
  margin-top: 10%;
  margin-bottom: 25px;
  text-align: center;
  font-size: 50px;
  font-weight: bold;

  letter-spacing: 1px;
  .span {
    color: #374957;
  }
}
i {
  color: #374957 !important;
}

.size {
  font-size: 29px;
  font-weight: 500;
  line-height: 52px;
  letter-spacing: 0.02em;
  text-align: center;
  color: #434343 !important;
  max-width: 386px;
}

.edit-title-header-image-teacher h1 {
  margin-top: 10%;
  margin-bottom: 25px;
  text-align: center;
  font-size: 3em;
  letter-spacing: 1px;
  border-bottom: 3px solid #b10000;
}

.fit-content-width {
  width: fit-content;
}

@media (min-width: 768px) {
  .marginTop-150-labtop {
    margin-top: 150px;
  }
}

.marginBottomLab {
  margin-bottom: 20px;
}

.fontSize-35 {
  font-size: 35px;
}

.fontSize-30-edit {
  font-size: 25px;
}

.ReactInputVerificationCode__item {
  background: #f2f5f6 0% 0% no-repeat padding-box !important;
  border-radius: 1px !important;
  border: 1px solid black;
  opacity: 1 !important;
  box-shadow: none !important;
  width: 4.5rem !important;
  height: 3.5rem;
}

@media (max-width: 767px) {
  .form-label {
    font-size: 0.7em !important;
  }
  .input-control {
    font-size: 0.7em !important;
  }
  .marginTop-150-labtop {
    margin-top: 30px;
    margin-bottom: 30px;
  }

  .marginTop-100-labtop {
    margin-top: 30px;
    margin-bottom: 30px;
  }

  .fontSize25 {
    font-size: 20px !important;
  }

  .edit-title-tabs-teacher {
    font-size: 20px !important;
  }

  .edit-section-student h3 {
    font-size: 18px;
  }

  .form-select {
    font-size: 14px;
  }

  .add-title-month-morinfo .second-btn {
    font-size: 15px !important;
  }

  .ReactInputVerificationCode__item {
    background: #f2f5f6 0% 0% no-repeat padding-box !important;
    border-radius: 1px !important;
    border: 1px solid black;
    opacity: 1 !important;
    box-shadow: none !important;
    width: 3.5rem !important;
    height: 3rem !important;
  }

  .fontSize20 {
    font-size: 14px !important;
  }

  .marginBottomLab {
    margin-bottom: 15px;
  }

  .label-color {
    font-size: 14px !important;
    color: #ffffff !important;
    background-color: white;
  }

  .fontSize-35 {
    font-size: 20px !important;
  }

  .fontSize-30-edit {
    font-size: 15px;
  }

  .send-forget-btn {
    padding: 12px 60px !important;
    font-size: 12px !important;
    width: 100%;
  }

  .logIn-title {
    font-size: 22px;
    margin-bottom: 8% !important;
    display: flex !important;
    align-items: center !important;
    text-align: center !important;
  }
  .login-label {
    color: #434343;
    margin-top: 15px !important;
  }
  .form-login {
    background: none !important;
    border: 2px solid #890405 !important;
    width: 100%;
    margin-top: 32px !important;
    border-radius: 10px;
  }
  .do-you-have {
    font-size: 0.7em;
    color: #434343 !important;
    text-decoration: none !important;
  }

  .input-group {
    position: relative;
  }

  .input-group input {
    display: block;
    width: 100%;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 5px;
  }

  .input-group input:focus + .form-label,
  .input-group input:not(:placeholder-shown) + .form-label {
    top: -20px;
    left: 0;
    color: #890405;
    font-size: 0.8rem;
    cursor: text;
  }

  .subject {
    font-size: 25px !important;
  }

  .edit-title-header-image h1 {
    color: white;
    margin-top: 23%;
    font-size: 30px;
    margin-bottom: 25px;
    text-align: center;
    border-bottom: 6px solid #b10000;
  }

  .size {
    padding: 0px 10px;
    font-size: 20px;
  }

  .description-plus {
    font-size: 23px;
    line-height: 34px;
  }

  .main-header-image {
    min-height: 68vh;
  }
}

.edit-title-header-image .second-btn:hover {
  margin-top: 15px;
  padding: 10px 30px;
  color: white;
  border: none;
  border-radius: 7px;
  border: 1px solid #707070;
  transition: 1s;
}

.edit-title-header-image p {
  line-height: 2;
  color: white;
}

/*End Main Header CSS*/

/*Start Tabs CSS*/
.nav-tabs .nav-link {
  border: none;
}

.home-icons {
  position: fixed;
  left: 26px;
  bottom: 26px;
  opacity: 70%;
  width: fit-content;
}

.nav-tabs .nav-item.show .nav-link,
.nav-tabs .nav-link.active {
  color: white;
  text-align: center;
  border-bottom: 3px solid #b10000 !important;
  background-color: black;
}

.nav-item {
  cursor: pointer;
}

.nav-tabs {
  border-bottom: 0;
}

.nav {
  justify-content: center;
}
.marginLeft {
  margin-left: 1em;
}
.swiper {
  width: 90%;
  min-height: 40vh;
}

.swiper-slide {
  text-align: center;
  font-size: 18px;
  background: black;

  /* Center slide text vertically */
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
}

.swiper-button-next {
  background-color: #d90000 !important;
  padding: 1px 20px !important;
  color: white !important;
  border-radius: 50%;
}

.swiper-button-prev {
  background-color: #d90000 !important;
  padding: 1px 20px !important;
  color: white !important;
  border-radius: 50%;
}

.swiper-button-prev:after {
  font-size: 15px !important;
}

.swiper-button-next:after {
  font-size: 15px !important;
}

.edit-title-tabs {
  font-size: 25px;
  font-weight: bold;
  margin-top: 6%;
  margin-right: 5%;
  margin-bottom: 4%;
  letter-spacing: 1.5px;
}

.edit-title-tabs-teacher {
  font-size: 25px;
  font-weight: bold;
  margin-top: 1%;
  margin-bottom: 2%;
  letter-spacing: 1.5px;
}

.edit-title-tabs-private {
  font-size: 1.5em;
  font-weight: bold;
  margin-top: 5%;
  margin-right: 5%;
  margin-bottom: 5%;
}

.border-style {
  border-bottom: 3px solid #ff0707;
}

.main-class-courses {
  text-align: start;
  background-color: #171717;
  color: white;
  border-radius: 15px;
}

.main-class-courses img {
  width: 100%;
  height: 211px;
  min-width: 320px;
}

.edit-title-icon {
  display: flex;
  justify-content: space-around;
}

.main-class-courses h1 {
  font-size: 20px;
  font-weight: bold;
  padding-top: 10px;
  padding-right: 20px;
}

.main-class-courses p {
  color: #979393;
  font-size: 15px;
  padding-right: 20px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}

.edit-title-icon {
  margin-bottom: 10px;
}

.edit-title-classes h3 {
  font-size: 23px;
}

.noLink {
  text-decoration: none !important;
}
.video-text {
  font-size: 0.8em;
  color: gray;
}
.fontSize-30 {
  font-size: 30px;
}

.fontSize18 {
  font-size: 18px !important;
}

.colorOpacity {
  color: rgb(218, 218, 218) !important;
}

.edit-icon-classes svg {
  background-color: #707070;
  padding: 1px 8px;
  border-radius: 50%;
  font-size: 35px;
  cursor: pointer;
}

.edit-icon-classesSaved svg {
  background-color: #b10000;
  padding: 1px 8px;
  border-radius: 50%;
  font-size: 35px;
  cursor: pointer;
}

.swiper-pagination {
  display: none;
}

.swiper-button-next,
.swiper-button-prev {
  top: 40% !important;
}

/*Start Second Tab*/

.main-class-training {
  text-align: start;
  background-color: #171717;
  color: white;
  border-radius: 15px;
  margin-bottom: 12px;
}

.main-class-training img {
  width: 100%;
}

.edit-title-icon {
  display: flex;
  justify-content: space-around;
}

.main-class-training h1 {
  font-size: 20px;
  font-weight: bold;
  padding-top: 10px;
  padding-right: 20px;
}

.main-class-training p {
  color: #979393;
  font-size: 15px;
  padding-right: 20px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}

.main-class-training .edit-title-classes h3 {
  padding-top: 5px;
  font-size: 15px;
  font-weight: 600;
}

.second-btn {
  padding: 5px 20px;
  color: white;
  background-color: black;
  border-radius: 7px;
  border: 1px solid #707070;
}

.second-btn1 {
  padding: 5px 15px;
  color: white;
  font-size: 16px;
  background-color: black;
  border-radius: 7px;
  border: 1px solid #707070;
}

.second-btn:hover {
  border: none !important;
  text-decoration: underline !important;
  text-underline-offset: 10px;
  text-decoration-color: #7a7a7a !important;
}
/* .second-btn:hover:after {
    border: none !important;
    text-decoration: underline !important;
    text-underline-offset: 10px;
    text-decoration-color: #7a7a7a !important;
    transition: width 1s !important;
    animation:  10s .5s;
    transform: scaleX(1);
    transform-origin: bottom left;
} */

.colorRed {
  color: #b10000 !important;
}

/*End Tabs CSS*/

/*Start SignUp CSS*/

.sign-up-card {
  background-color: #f4f4f4 !important;
  border-radius: 46px;
  box-shadow: -4px 1px 32px 0px #00000040;
  width: 80%;
  height: auto;
  margin-top: 7em;
  margin-bottom: 7em;
  padding: 5em;
  /* padding: 56px 91px 74px 91px; */
  /* margin-bottom: 240px !important;
    margin-top: 89px !important; */
  justify-content: center !important;
  .h1 {
    color: #434343;
    /* font-size: 38.72px; */
    font-weight: 600;
    line-height: 180%;
    letter-spacing: 0.774px;
    text-align: center !important;
  }
}

.sign-up-text {
  color: #434343 !important;
  /* font-size: 23.72px !important; */
  font-weight: 300 !important;
  line-height: 180%;
  letter-spacing: 0.474px;
}

.form-label {
  /* position: relative;
    top: -19px;
    right: -590px;  */
  font-size: 1em;
  color: #9c9c9c;
  background: #f4f4f4;
  width: fit-content;
  padding: 2px 10px !important;
  margin-top: -1em;
  justify-content: right;
}
.drop-input {
  background: none;
  border: none;
}

.form-control {
  display: flex;
  flex-direction: column;
  background: none !important;
  border: 2px solid #890405 !important;
  width: 100%;
  margin-top: 12px !important;
  border-radius: 10px;
}
.form-control-teacher {
  height: 41px;
  background: none !important;
  border: 2px solid #890405 !important;
  width: 100%;
  margin-top: 8px !important;
  padding-right: 10px;
  border-radius: 10px;
  box-sizing: unset !important;
}

.input-control {
  width: 100%;
  background: none !important;

  border: none;
  &:focus {
    border: none !important;
  }
}

/* .modal-inputs-form {
    background-color: white !important;
    border: 1px solid #9C9C9C !important;
    width: 100%;
    color: black !important;
} */

.edit-login-button .first-btn {
  padding: 10px 95px;
  color: white !important;
  border-radius: 50px !important;
  border: 1px solid #890405;
  background: #890405;
  border: 0;
  text-align: center;
  margin-bottom: 31px;
  margin-top: 15px;
  color: #fff;
  font-size: 25px;
  line-height: 180%;
  letter-spacing: 0.5px;
  width: 65%;
}
.edit-login-button .second-btn {
  width: 694px;
  height: 75px;
  padding: 10px 95px;
  color: #890405 !important;
  border-radius: 50px !important;
  border: 1px solid #890405 !important;
  background: white;
  border: 0;
  text-align: center;
  margin-bottom: 31px;
  color: #fff;
  font-size: 25px;
  line-height: 180%;
  letter-spacing: 0.5px;
  &:hover {
    background: #890405;
    color: white !important;
    text-decoration: none !important;
  }
}
.edit-login-button .border-btn {
  color: red;
  border-radius: 20px;
  padding: 10px 20px;
  background-color: white;
  width: 100%;
  &:hover {
    color: white !important;
    background: #890405 !important;
    text-decoration: none !important;
    transition-delay: 0.1s;
    opacity: 1;
  }
}
.spinner-border {
  color: white;
}
.edit-login-another .first-btn {
  padding: 10px 40px;
  color: white;
  background-color: #1777f2;
  border-radius: 7px;
  border: 0;
  text-align: center;
}
.border-none {
  border: none;
  background: none;
}

.edit-login-another svg {
  margin-left: 10px;
  font-size: 20px;
}

@media (max-width: 767px) {
  .edit-login-another .first-btn {
    padding: 10px 30px;
    color: white;
    background-color: #1777f2;
    border-radius: 7px;
    border: 0;
    text-align: center;
  }
  .drop-input {
    font-size: 0.7em;
  }
  .main-header-icon {
    display: none;
  }
  .expert {
    margin-top: 65px;
  }

  .edit-title-tabs {
    font-size: 16px;
  }

  .main-class-courses h1 {
    font-size: 18px;
  }

  .edit-title-classes h3 {
    font-size: 20px;
  }
  .video-text {
    font-size: 0.5em;
    color: gray;
    line-height: 1.5em;
  }

  .border-style {
    font-size: 19px !important;
  }
  /* .card_form {
        box-shadow: #0000000;
        padding: 30px 40px;
        background: none;
        border-radius: 7px;
    } */

  .add-two-button .first-btn {
    font-size: 13px;
  }

  .add-two-button .second-btn {
    font-size: 13px;
  }

  .nav-link {
    font-size: 13px;
  }

  .edit-size-input {
    font-size: 13px;
  }

  .edit-login-button .first-btn {
    padding: 10px 82px;
    color: white;
    text-align: center;
    margin-bottom: 5px;
    font-size: 9px;
  }
}

.do-you-have {
  font-weight: 500;
  line-height: 180%;
  letter-spacing: 0.354px;
}

.do-you-have a {
  color: #b10000 !important;
  text-decoration: underline !important;
  font-weight: 900 !important;
}

.do-you-have span {
  color: #434343 !important;
  padding-left: 8px;
}

#edit-forget {
  color: #00cbff;
  text-decoration: none;
  cursor: pointer;
}

/*End SignUp CSS*/

/*Start Progress CSS*/
.edit-progress-style {
  display: flex;
  justify-content: center;
  padding-bottom: 20px;
}

.edit-progress {
  color: white;
  text-align: center;
}

.edit-dir-number {
  direction: ltr !important;
  padding-right: 20px;
  font-size: 17px;
  font-weight: bold;
  color: #707070;
}

.edit-btn-progress {
  font-weight: bold;
  font-size: 20px;
  padding: 12px 50px;
  color: #f5f5f5;
  background-color: #0097ef;
  border-radius: 35px;
  border: 1px solid #707070;
  margin-left: 10px;
  margin-bottom: 15px;
}

.ant-progress-bg {
  height: 21px !important;
}

.edit-btn-prog {
  font-weight: bold;
  font-size: 20px;
  padding: 5px 10px;
  color: #f5f5f5;
  background-color: #282828;
  border-radius: 20px;
  border: 1px solid #707070;
  margin-bottom: 15px;
  width: 100%;
}

.edit-section-student h3 {
  color: #06a3ff;
  font-weight: bold;
  margin-left: 6%;
  margin-bottom: 2%;
}

@media (max-width: 767px) {
  .edit-btn-prog {
    font-weight: bold;
    font-size: 18px;
    padding: 5px 10px;
    color: #f5f5f5;
    background-color: #282828;
    border-radius: 20px;
    border: 1px solid #707070;
    margin-bottom: 15px;
    width: 100%;
  }
}

@media (min-width: 767px) {
  .edit-btn-prog {
    font-weight: bold;
    font-size: 20px;
    padding: 5px 10px;
    color: #f5f5f5;
    background-color: #282828;
    border-radius: 20px;
    border: 1px solid #707070;
    margin-bottom: 15px;
    width: 100%;
  }
}

.main {
  margin-bottom: 15px;
}

.main-one {
  display: flex;
}

.main-two {
  display: flex;
}

.edit-btn-prog1 {
  font-weight: bold;
  font-size: 20px;
  padding: 5px 10px;
  color: #f5f5f5;
  background-color: #282828;
  border-radius: 20px;
  border: 1px solid #707070;
  margin-bottom: 15px;
  width: 95%;
}

@media (max-width: 1025px) {
  .edit-btn-prog1 {
    font-weight: bold;
    font-size: 16px;
    padding: 5px 3px;
    color: #f5f5f5;
    background-color: #282828;
    border-radius: 20px;
    border: 1px solid #707070;
    margin-bottom: 15px;
    width: 95%;
  }
}

@media (max-width: 767px) {
  .edit-btn-prog1 {
    font-weight: bold;
    font-size: 15px;
    padding: 5px 10px;
    color: #f5f5f5;
    background-color: #282828;
    border-radius: 20px;
    border: 1px solid #707070;
    margin-bottom: 15px;
    width: 95%;
  }
}

@media (max-width: 321px) {
  .edit-btn-prog1 {
    font-weight: bold;
    font-size: 12px;
    padding: 5px 5px;
    color: #f5f5f5;
    background-color: #282828;
    border-radius: 20px;
    border: 1px solid #707070;
    margin-bottom: 15px;
    width: 95%;
  }
}

/*End Progress CSS*/

/*Start Pricing CSS*/
.main-pricing {
  color: white;
}

.main-pricing-title {
  background-color: #949494;
  color: white;
  min-width: 50%;
  text-align: center;
  margin: auto;
}

.main-pricing-title h2 {
  font-weight: bold;
}

.edit-parag-title {
  display: flex;
  justify-content: space-around;
  align-items: center;
  background-color: black;
  border: 1px solid #707070;
  border-radius: 10px;
  padding-top: 10px;
  margin: auto;
  font-weight: bold;
  min-width: 50%;
  padding-inline: 10px;
}

@media (min-width: 1024px) {
  .main-pricing-title {
    background-color: #949494;
    color: white;
    max-width: 50%;
    text-align: center;
    margin: auto;
  }
}

.add-line-through {
  text-decoration-line: line-through;
  text-align: center;
}

.edit-price-here {
  background-color: #404040a1;
  border-radius: 10px;
  width: 170px;
  text-align: center;
  min-height: 30px;
}

.edit-skip {
  font-size: 20px;
  font-weight: bold;
  text-decoration: none;
  color: #00b0fe;
}

/*End Pricing CSS*/

/*Start Skip CSS*/
.title-payment {
  background-color: #282828;
  color: white;
  max-width: 75%;
  min-height: 60px;
  text-align: center;
  margin: auto;
  border-radius: 10px;
  font-weight: bold;
}

@media (max-width: 767px) {
  .title-payment {
    background-color: #282828;
    color: white;
    max-width: 100%;
    min-height: 70px;
    text-align: center;
    margin: auto;
    border-radius: 10px;
    font-weight: bold;
  }
}

.btn-skip {
  text-align: center;
}

/*End Skip CSS*/

/*Start Single Course CSS*/

.main-head-title-singleCourse {
  display: flex;
  border-bottom: 1px solid #d5d5d5;
}

.main-head-title-singleCourse img {
  width: 75px;
  margin-left: 15px;
  margin-bottom: 10px;
}

.edit-singleCourse-titleHere1 {
  margin-right: 22%;
  margin-bottom: 4%;
}

@media (max-width: 768px) {
  .edit-singleCourse-titleHere1 {
    margin-right: 30%;
  }
}

.edit-singleCourse-titleHere1 h5 {
  padding-bottom: 10px;
}

.edit-singleCourse-titleHere-span {
  color: gray !important;
  border: 1px solid gray;
  padding: 5px 10px;
  margin-top: 15px;
}

.edit-singleCourse-titleHere p {
  color: gray !important;
}

.edit-singleCourse-titleHere-spanIcon {
  font-size: 25px;
  padding-right: 20px;
  cursor: pointer;
}

.edit-singleCourse-titleHere-spanIcon svg {
  color: gray !important;
}

.edit-img-updataDone img {
  width: 70px;
  height: 70px;
  border-radius: 50%;
  position: absolute;
  background-color: gray;
  margin-bottom: 10px;
}

.edit-singleCourse-video iframe {
  border-radius: 10px;
  width: 100%;
}

.edit-number-class {
  margin-top: 5%;
}

.edit-number-class p {
  color: gray !important;
  padding-right: 10px;
}

.edit-description-train p {
  color: gray !important;
}

.make-skills-flex {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
}

.edit-your-skills {
  margin-top: 5%;
}

.edit-skills-span .second-btn {
  padding: 5px 20px;
  color: white;
  background-color: #b10000;
  border-radius: 7px;
  border: 1px solid #707070;
}

.edit-skills-span .edit-bookmark-skill {
  font-size: 25px;
  margin-right: 10px;
  margin-bottom: 5px;
}

.edit-start-skill svg {
  margin-right: 10px;
  font-size: 20px;
  color: red !important;
}

@media (max-width: 767px) {
  .make-skills-flex {
    flex-direction: column;
    align-items: unset;
  }
}

.edit-btn-download .second-btn {
  min-width: 40%;
  color: white;
  background-color: #b10000;
  border-radius: 7px;
  border: 1px solid #707070;
  margin-left: 15px;
}

.main-lecture-singleCourse {
  margin-top: 5%;
  margin-bottom: 3%;
  background-color: #323030;
  padding-top: 8px;
  min-width: 50%;
  border-radius: 10px;
}

.main-lecture-singleCourseWithout {
  margin-top: 5%;
  margin-bottom: 3%;
  padding-top: 8px;
  min-width: 50%;
  border-radius: 10px;
}

.backAndDesign {
  margin-top: 5%;
  margin-bottom: 3%;
  padding-top: 8px;
  min-width: 50%;
  border-radius: 10px;
}

@media (max-width: 767px) {
  .main-lecture-singleCourse {
    text-align: center;
    padding-bottom: 10px;
  }

  .main-lecture-singleCourseWithout {
    text-align: center;
    padding-bottom: 10px;
  }
}

.edit-inquiries-title {
  display: flex;
  justify-content: space-between;
}

.edit-inquiries-title p {
  color: gray !important;
}

.edit-inquiries-spanTwo-title {
  color: gray !important;
}

.edit-add-comment-inquiries {
  display: flex;
  justify-content: space-between;
}

@media (max-width: 767px) {
  .edit-add-comment-inquiries {
    flex-direction: column;
    align-items: center;
  }
}

.edit-your-skillss {
  display: flex;
  margin-top: 15px;
}

edit-this-skill1 {
  font-size: 25px;
  color: red !important;
}

.edit-this-skill {
  color: gray !important;
  padding-top: 5px;
}

.add-title-month-morinfo {
  text-align: center;
  margin-top: 5%;
}

.add-title-month-morinfo .second-btn {
  padding: 10px 50px;
  color: white;
  font-size: 20px;
  background-color: #dc0404;
  border-radius: 7px;
  border: 1px solid #707070;
}

.add-title-month-morinfo .second-btn2 {
  padding: 10px 15px;
  min-width: 40%;
  color: white;
  background-color: #404040b3;
  border-radius: 7px;
  border: 1px solid #707070;
}

/*End Single Course CSS*/

.tooltip1 {
  position: relative;
  display: inline-block;
  border-bottom: 1px dotted black;
}

/* .dropdown-menu::after {
    content: "";
    position: absolute;
    bottom: 100%;
    left: 3%;
    margin-left: -5px;
    border-width: 35px;
    border-style: solid;
    border-color: transparent transparent #2A2A2AB8 transparent;
} */

.first-row-setting {
  display: flex;
  align-items: center;
  justify-content: space-around;
  border-bottom: 1px solid gray;
}

.edit-second-row-setting {
  display: flex;
  align-items: baseline;
}

.second-row-setting {
  margin-top: 10px;
  padding-inline: 5px;
  border-bottom: 1px solid gray;
}

.second-row-setting p {
  padding-right: 10px;
}

.edit-third-row-setting {
  display: flex;
  align-items: baseline;
}

.edit-here-navbarr1 .first-row-setting1 {
  text-align: start;
  padding-inline: 15px;
  border-bottom: 1px solid gray;
  margin-top: 5px;
}

.third-row-setting {
  margin-top: 10px;
  padding-inline: 5px;
  text-align: start;
}

.third-row-setting p {
  padding-right: 10px;
}

.all {
  position: relative;
}

.edit-here-navbarr {
  position: absolute;
  left: 80px;
  top: 28px;
  z-index: 9999999999999999;
  max-width: 8em;
  display: flex;
  align-items: center;
}

.edit-here-navbarrParent {
  position: absolute;
  left: 10px !important;
  top: 0px !important;
  z-index: 9999999999999999;
}

.edit-here-navbarr1 {
  position: absolute;
  left: 145px;
  top: 12px;
  z-index: 9999999999999999;
}

@media (max-width: 1025px) {
  .edit-here-navbarrParent {
    position: absolute !important;
  }
}

@media (max-width: 767px) {
  .navbar-brand img {
    width: 150px;
  }
  .edit-here-navbarr {
    display: none;
  }

  .navbar-brand {
    margin-right: 0px;
  }

  .notification .dropdown-menu.show {
    display: flex;
    width: 275px;
    min-height: 250px;
    flex-direction: column;
  }

  .DarkMode svg {
    background-color: #707070;
    padding: 1px 6px;
    border-radius: 50%;
    font-size: 30px;
    margin-right: 30px;
  }

  .edit-here-navbarr1 {
    position: absolute;
    left: 115px;
    top: 8px;
    z-index: 9999999999999999;
  }

  .edit-here-navbarr {
    position: absolute;
    left: 55px;
    top: 20px;
    z-index: 9999999999999999;
  }

  .notification .dropdown-menu.show {
    display: flex;
    min-width: 300px;
    flex-direction: column;
  }

  .navbar-toggler {
    background-color: none !important;
    padding: 0.1rem 0.25rem;
    font-size: 1rem;
  }

  .notification .dropdown-menu {
    min-width: 0% !important;
  }

  .edit-title-header-image p {
    text-align: center !important;
  }

  .He {
    margin-top: 10px;
  }
}

@media (min-width: 768px) {
  .He {
    margin-top: 10px;
  }
}

@media (max-width: 320px) {
  .notification .dropdown-menu::after {
    content: "";
    position: absolute;
    bottom: 100%;
    left: 25.5%;
    margin-left: -5px;
    border-width: 35px;
    border-style: solid;
    border-color: transparent transparent #000000ff transparent;
  }
}

.No-Here .dropdown-menu::after {
  display: none;
}

.Edit-Continue .first-btnOne {
  min-width: 20%;
  padding: 10px 15px;
  color: white;
  background-color: #b10000;
  border-radius: 7px;
  border: 0;
  font-weight: bold;
}

.form-select {
  background-color: #2d2d2d !important;
  padding: 0 !important;
}

/*main-course-info*/
.edit-all-info {
  margin: auto;
}

.edit-main-course-info {
  background-color: #1e1e1e;
  text-align: center;
  line-height: 3;
  padding-top: 10px;
  width: 60%;
  margin: auto;
  border-radius: 10px;
}

.edit-main-course-info h3 {
  font-weight: bold;
}

/*///////////////////////*/

.inputRadio:hover input ~ .checkmark {
  background-color: rgba(22, 141, 87, 0.62);
}

.inputRadio input:checked ~ .checkmark {
  background-color: #009045;
}

.inputRadio input:checked ~ .coloring {
  color: #009045 !important;
}

.coloring {
  color: black !important;
}

/* .checkmark:after {
} */

.inputRadio input:checked ~ .checkmark:after {
  display: block;
}

.inputRadio .checkmark:after {
  top: -3px;
  left: 22px;
  width: 0px;
  height: 6px;
  border-radius: 50%;
  background: white;
}

.inputRadio {
  display: block;
  position: relative;
  padding-right: 30px;
  margin-bottom: 0px;
  cursor: pointer;
  font-size: 14px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.inputRadio input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
}

.formInputRadios {
  border-radius: 7px !important;
  padding: 0.175rem 0.05rem !important;
}

.checkmark {
  position: absolute;
  top: 50%;
  right: 0;
  height: 10px;
  width: 15px;
  background-color: lightgray;
  border-radius: 50%;
}

.edit-input-pricing {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.checkmark:after {
  content: url("../images/checkedInput.svg");
  position: absolute;
  display: none;
}

.edit-parag-title1 {
  display: flex;
  border: 1px solid gray;
  align-content: center;
  padding-top: 10px;
  margin-bottom: 30px;
  padding-inline: 15px;
}

.edit-Choose-Pay .first-btn {
  padding: 8px 10px;
  min-width: 50%;
  color: white;
  background-color: #2d2d2d;
  border-radius: 7px;
  border: 0;
}

.add-whatsapp-icon {
  margin-right: 5%;
}

.edit-btn-start button {
  padding: 10px 30px;
  color: white;
  background-color: #b10000;
  border-radius: 7px;
  border: 0;
}
/*start main page css */
.card_form {
  width: fit-content;
}
.card_form h1 {
  color: #890405 !important;
  font-size: 37px;
  font-weight: 500 !important;
  line-height: 15px;
  color: white;
  padding-bottom: 4px;
}

.card_form p {
  color: #696984;
  font-size: 21px;
  font-weight: 400;
  line-height: 34px;
}
.card_image {
  background-image: url("../images/gray.png");

  background-repeat: no-repeat;
  padding-right: 30px;
  background-position: center;
}
.stem {
  margin-right: 60px;
}
.home-card {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-right: 10px;
}

.home-card h3 {
  font-size: 2em;
  font-weight: 700;
  line-height: 45px;
  color: #890405 !important  ;
}
.home-card p {
  font-size: 0.75em;
  font-weight: 400;
  line-height: 22px;
}

.card-btn {
  font-size: 1em;
  font-weight: 500;
  line-height: 28px;
  width: fit-content;
  align-self: center;
  flex-wrap: nowrap;
  background: none;
  border: 1px solid #890405;
  border-radius: 10px;
  color: #890405 !important;
  padding: 0 1em;
  box-shadow: 0px 10px 60px 0px #262d7614;
  &:hover {
    background: #890405;
    color: white !important;
    transition: 5ms;
  }
}
/*Start Quizzes CSS*/
.sub-question {
  background-color: #404040b3;
  padding: 10px 20px;
  margin-bottom: 2%;
  border-radius: 15px;
  cursor: pointer;
}

.start-question {
  padding-inline: 5px;
}

.edit-timer-question {
  text-align: center;
  margin-top: 2%;
}

.add-btn-end-question {
  display: flex;
  justify-content: center;
  margin-top: 4%;
}

.edit-btn-start {
  display: flex;
  justify-content: end;
}

.edit-btn-start h3 {
  padding-left: 10px;
  color: #9d9d9d !important;
}

.add-btn-end-question .edit-btn-start {
  padding-right: 3%;
}

.add-btn-end-question .edit-btn-next button {
  padding: 10px 30px;
  color: white;
  background-color: #b10000;
  border-radius: 7px;
  border: 0;
}

.true-question {
  background-color: #27a641;
  color: white;
}

.false-question {
  background-color: #bf0000;
  color: white;
}

.edit-svg-question svg {
  font-size: 25px;
}

.edit-title-result-quizzes {
  display: flex;
}

.edit-title-result-quizzes svg {
  font-size: 30px;
  margin-left: 15px;
  margin-top: 3px;
  cursor: pointer;
}

.edit-total-result {
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: auto;
  background-color: #2d2d2d;
  width: 385px;
  height: 350px;
  border-radius: 50%;
}

.add-border-total-result1 {
  border: 2px solid red;
  border-radius: 50%;
  padding: 20% 20%;
  width: 200px;
  height: 200px;
}

.add-border-total-result h3 {
  font-weight: bold;
}

@media (max-width: 767px) {
  .start-question h2 {
    padding-top: 10px;
    font-size: 20px;
  }
}

h2 {
  color: #aaaaaa;
  text-decoration: none;
}

.form-check .form-check-input {
  float: right;
  margin-right: 3.5em;
  margin-left: 3.5em;
  margin-top: 15px;
}

.form-check-input:checked {
  background-color: #a7a7a7;
  border-color: #f5f5f5;
}

.form-check-input {
  background-color: black;
  border-color: #f5f5f5;
}

@media (max-width: 767px) {
  .form-check .form-check-input {
    float: right;
    margin-right: 0em;
  }
}

/*End Quizzes CSS*/
.eshra7-plus {
  background-position: center;
  background-size: cover;
  width: 100%;
  margin: 0px 25px;
  position: relative;
}

.eshra7-plus:first-child {
  margin: 0 !important;
}

.eshra7-plus:last-child {
  margin: 0 !important;
}

.eshra7-plus-two {
  height: 370px;
  background-position: center;
  background-size: cover;
  width: 100%;
  margin: 0px 25px;
  position: relative;
  background: linear-gradient(rgba(137, 4, 5, 0) 0%, #890405 100%);
  color: white;
}
.eshra7-plus-two-2 {
  height: 370px;
  background-position: center;
  background-size: cover;
  width: 100%;
  margin: 0px 25px;
  position: relative;
  background: linear-gradient(rgba(137, 4, 5, 0) 0%, #890405 100%);
  color: white;
}
.z-index-1 {
  position: relative;
  z-index: 3;
}

.eshra7-plus-two::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-image: url("../images/private\ section.png");
  background-size: cover;
  clip-path: polygon(0 0, 100% 0, 100% 100%, 0 100%);
}

.eshra7-plus-two::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(rgba(137, 4, 5, 0) 0%, #890405 100%);
  clip-path: polygon(0 0, 100% 0, 100% 100%, 0 100%);
}
.eshra7-plus-two-2::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-image: url("../images/all\ subjects.png");
  background-size: cover;
  clip-path: polygon(0 0, 100% 0, 100% 100%, 0 100%);
}

.eshra7-plus-two-2::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(rgba(137, 4, 5, 0) 0%, #890405 100%);
  clip-path: polygon(0 0, 100% 0, 100% 100%, 0 100%);
}

.subscription-title {
  font-size: 30px;
  color: white !important;
  z-index: 999;
}
.d-content {
  display: contents;
}

.subscription-subtitle {
  font-size: 17px;
  color: white !important;
  z-index: 999;
}

/* .eshra7-plus-two::before {
    content: "";
    background-image: url("../images/talking-audience.png");
    box-shadow: inset  solid(rgba(137, 4, 5, 0));
    background-size: cover;
    position: absolute;
    top: 0px;
    right: 0px;
    bottom: 0px;
    left: 0px;
    opacity: 0.5;
} */

.eshra7-plus-private-section::before {
  background-image: url("../images/private\ section.png") !important;
  cursor: pointer;
}

.eshra7-plus-all-sections::before {
  background-image: url("../images/all\ subjects.png") !important;
  cursor: pointer;
}

.eshra7-plus-private-content::before {
  background-image: url("../images/private\ content.png") !important;
  cursor: pointer;
}

.eshra7-plus-public-content::before {
  background-image: url("../images/public\ content.png") !important;
  cursor: pointer;
}
.student-bg {
  background-image: url("../images/student.svg");
  background-repeat: no-repeat;
  background-size: cover;
  background-position-x: inherit;
}

.eshra7-plus-two:first-child {
  margin: 0 !important;
}

.eshra7-plus-two:last-child {
  margin: 0 !important;
}

.eshra7-plus-three {
  height: 250px;
  background-position: center;
  background-size: cover;
  width: 100%;
  margin: 0px 25px;
  position: relative;
}

.eshra7-plus-three::before {
  content: "";
  background-image: url("../images/talking-audience.png");
  background-size: cover;
  position: absolute;
  top: 0px;
  right: 0px;
  bottom: 0px;
  left: 0px;
  opacity: 0.5;
}

.eshra7-plus-three:first-child {
  margin: 0 !important;
}

.eshra7-plus-three:last-child {
  margin: 0 !important;
}

.flex-manual {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  justify-content: center;
  align-items: center;
  justify-items: center;
  gap: 203px 30px;
}

.flex-manual-two {
  display: grid;
  grid-template-columns: 1fr 1fr;
  justify-content: center;
  align-items: center;
  justify-items: center;
  gap: 20px 30px;
}

.flex-manual-three {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  justify-content: center;
  align-items: center;
  justify-items: center;
  gap: 20px 30px;
}
.date {
  color: #777795;
  font-size: 10px;
  line-height: 15px;
}
.card {
  height: 377px;
}
.desc {
  font-size: 10px;
  line-height: 16px;
  color: #4d4d4d !important;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 3; /* Number of lines to show */
  -webkit-box-orient: vertical;
  max-height: 3 * 1.2em; /* Adjust this value based on your font-size */
}
.card_data {
  font-size: 1em;
  line-height: 16px;
  color: #4d4d4d !important;
}

.cardCol {
  display: inline-grid;
}
.sub {
  font-size: 1.5em;
  font-weight: bolder;
  color: black !important;
  text-align: right !important;
}
.price {
  color: #374957;
  font-size: 16px;
  line-height: 25px;
  font-weight: 900;
  margin-bottom: 0px !important;
}
.old-price {
  font-size: 14px;
  color: #230f0f;
  line-height: 22px;
  font-weight: 400px;
  text-decoration: line-through;
  align-self: center;
  margin-bottom: 0px !important;
}

.course-card-btn {
  font-size: 12px;
  font-weight: 500px;
  line-height: 22px;
  background: none;
  width: fit-content;
  border: 1px solid #890405;
  border-radius: 5px;
  text-decoration: none;
  color: #890405 !important;
  padding: 0 20px;
  box-shadow: 0px 10px 60px 0px #262d7614;
  &:hover {
    background: #890405;
    color: white !important;
    transition: 5ms;
  }
}
.more-card-btn {
  font-size: 12px;
  font-weight: 500px;
  line-height: 22px;
  width: fit-content;
  border: 1px solid #890405;
  border-radius: 10px;
  background: #890405;
  color: white !important;
  padding: 0 20px;
  box-shadow: 0px 10px 60px 0px #262d7614;
  text-decoration: none;
  padding-right: 51px;
  padding-left: 51px;
  padding-top: 5px;
  padding-bottom: 5px;
}

.why-choose-us {
  background-color: #ab181e;
  background-image: url("../images/whyPattern.png");
  background-repeat: no-repeat;
  background-position: bottom;
  border-radius: 16px;
  color: white;
  margin-right: 0px !important;
  margin-top: 100px;
}

.why-header {
  color: white;
  font-size: 42px;
  font-weight: 700;
  line-height: 62px;
  padding: 15px;
}

.why-text {
  font-size: 12px;
  font-weight: 500;
  line-height: 15px;
  letter-spacing: 0.02em;
  margin-bottom: 20px;
  margin-right: 20px;
}
.why-btn {
  text-decoration: none;
  /* margin: 10px auto; */
  font-size: 16px;
  font-weight: 500px;
  line-height: 22px;
  width: fit-content;
  background-color: white;
  border-radius: 10px;
  color: #890405 !important;
  padding: 15px 30px 15px 30px;
  margin-bottom: 15px;
  border: none;
  align-self: flex-end;
  text-decoration: none;
}
.girl {
  margin-top: -75px;
}

@media (max-width: 768px) {
  .girl {
    display: none;
  }
  .half-footer {
    display: none;
  }
  .main-footer {
    background-blend-mode: soft-light;
  }
  .footer-col-right {
    display: none;
  }
  .mob-none {
    display: none;
  }
  .row-mob {
    justify-content: center;
    margin: 0;
  }
  .social-media-account {
    justify-content: center;
    margin-top: 0px !important;
    gap: 2em;
  }
  .sub-social {
    margin-left: 0px !important;
  }
}

.why-us {
  padding-bottom: 45px;
}
.expert-all {
  display: inline-flex;
  flex-direction: column;
  align-items: center;
}

.expert {
  background: #f0d0d1;
  width: 170px;
  height: 170px;
  border-radius: 50%;
  display: inline-flex;
  justify-content: center;
  flex-flow: wrap-reverse;
}
.expert-name {
  font-size: 21px;
  font-weight: 700;
  line-height: 21px;
  color: #000000;
  padding: 20px 0px 3px 0px;
}

.expert-title {
  font-size: 14px;
  font-weight: 500;
  line-height: 14px;
  color: #000000;
}
@media (max-width: 1025px) {
  .flex-manual {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    justify-content: center;
    align-items: center;
    justify-items: center;
    gap: 20px 30px;
  }

  .flex-manual-three {
    display: grid;
    grid-template-columns: 1fr 1fr;
    justify-content: center;
    align-items: center;
    justify-items: center;
    gap: 20px 30px;
  }
}

@media (max-width: 769px) {
  .flex-manual {
    display: grid;
    grid-template-columns: 1fr 1fr;
    justify-content: center;
    align-items: center;
    justify-items: center;
    gap: 20px 30px;
  }

  .flex-manual-three {
    display: grid;
    grid-template-columns: 1fr 1fr;
    justify-content: center;
    align-items: center;
    justify-items: center;
    gap: 20px 30px;
  }
}

@media (max-width: 1000px) {
  .flex-manual-two {
    display: grid;
    grid-template-columns: 1fr;
    justify-content: center;
    align-items: center;
    justify-items: center;
    gap: 20px 30px;
  }

  .eshra7-plus-two {
    height: 300px;
    background-position: center;
    background-size: cover;
    width: 100%;
    margin: 0px 25px;
    position: relative;
  }
}

@media (max-width: 426px) {
  .flex-manual {
    display: grid;
    grid-template-columns: 1fr;
    justify-content: center;
    align-items: center;
    justify-items: center;
    gap: 20px 30px;
  }

  .flex-manual-three {
    display: grid;
    grid-template-columns: 1fr;
    justify-content: center;
    align-items: center;
    justify-items: center;
    gap: 20px 30px;
  }
}

.flex-manual-teachers {
  padding: 0px 0px 0px 0px;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
  justify-content: center;
  align-items: center;
  justify-items: center;
  gap: 20px 30px;
}

@media (max-width: 1025px) {
  .flex-manual-teachers {
    padding: 0px 0px 0px 0px;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
    justify-content: center;
    align-items: center;
    justify-items: center;
    gap: 20px 30px;
  }
}

@media (max-width: 769px) {
  .flex-manual-teachers {
    padding: 0px 0px 0px 0px;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
    justify-content: center;
    align-items: center;
    justify-items: center;
    gap: 20px 30px;
  }
}

@media (max-width: 426px) {
  .flex-manual-teachers {
    padding: 0px 0px 0px 0px;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    justify-content: center;
    align-items: center;
    justify-items: center;
    gap: 20px 30px;
  }
}

@media (max-width: 321px) {
  .flex-manual-teachers {
    padding: 0px 0px 0px 0px;
    display: grid;
    grid-template-columns: 1fr 1fr;
    justify-content: center;
    align-items: center;
    justify-items: center;
    gap: 20px 30px;
  }
}

.subject {
  opacity: 1 !important;
  font-size: 1px !important;

  position: relative;

  text-align: center;
  letter-spacing: 1.5px;
}

.mt-100 {
  margin-top: 100px;
}

.mt-50 {
  margin-top: 50px;
}

.teachers h1 {
  color: white;
  margin-top: 5%;
  margin-bottom: 3%;
  text-align: center;
  font-weight: bold;
  letter-spacing: 1.5px;
}

/* .cc-selector input {
    margin: 0;
    padding: 0;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
} */

.visa {
  background-image: url("../images/teacher.png");
}

.cc-selector input {
  margin: 0;
  padding: 0;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}

.cc-selector-2 input {
  position: absolute;
  z-index: 999;
}

.cc-selector-2 input:active + .drinkcard-cc,
.cc-selector input:active + .drinkcard-cc {
  opacity: 0.9;
}

.cc-selector-2 input:checked + .drinkcard-cc,
.cc-selector input:checked + .drinkcard-cc {
  -webkit-filter: contrast(1.2);
  -moz-filter: contrast(1.2);
  filter: contrast(1.2);
}

.cc-selector-2 input:checked + .drinkcard-cc,
.cc-selector input:checked + .drinkcard-cc:after {
  visibility: visible;
}

.drinkcard-cc {
  cursor: pointer;
  background-size: contain;
  background-repeat: no-repeat;
  display: inline-block;
  width: 95px;
  height: 90px;
  -webkit-transition: all 100ms ease-in;
  -moz-transition: all 100ms ease-in;
  transition: all 100ms ease-in;
  /*-webkit-filter: brightness(1.8) grayscale(1) opacity(.7);*/
  /*-moz-filter: brightness(1.8) grayscale(1) opacity(.7);*/
  /*filter: brightness(1.8) grayscale(1) opacity(.7);*/
}

.drinkcard-cc:after {
  content: "✓";
  top: 0px;
  font-size: 60px;
  right: 22px;
  position: absolute;
  visibility: hidden;
}

.drinkcard-cc:hover {
  -webkit-filter: contrast(1.2);
  -moz-filter: contrast(1.2);
  filter: contrast(1.2);
}

/* Extras */
a:visited {
  color: #888;
}

.cc-selector-2 input {
  margin: 5px 0 0 12px;
}

/* .cc-selector-2 label {
    margin-left: 7px;
} */

span.cc {
  color: #6d84b4;
}

.create-card {
  background-color: #e91b1b;
  border-radius: 15px;
  cursor: pointer;
  color: white;
}

.create-card p {
  padding-top: 7px;
  padding-right: 5px;
}

.edit-card-p {
  font-weight: bold;
  text-align: end;
  padding-left: 10px;
  padding-bottom: 10px;
}

@media (max-width: 767px) {
  .create-card {
    margin-top: 10px;
  }
}

.add-book-here {
  background-color: #e71c23;
  color: white;
  padding: 10px 15px;
  border-radius: 10px;
  text-align: center;
}

.add-book-here svg {
  margin-left: 10px;
  font-size: 17px;
}

.add-price-book {
  margin-top: 5%;
  text-align: center;
  background-color: #2d2d2d;
  border: 1px solid #707070;
  padding: 10px 15px;
  border-radius: 10px;
}

.add-input-books {
  padding: 5% 5%;
  width: 100%;
  border-radius: 10px;
}

.edit-sub-input-books {
  display: flex;
}

.edit-sub-input-books p {
  padding-top: 4px;
  width: 30%;
}

.clear-line a {
  text-decoration: none !important;
}

@media (max-width: 767px) {
  .edit-sub-input-books p {
    padding-top: 4px;
    width: 50%;
  }

  .create {
    padding-top: 15px;
  }
}

.form-check-input:checked[type="radio"] {
  background-image: url("../images/2075831_accept_approve_check_true_icon.png") !important;
}

.start-question p {
  color: #a1a1a1 !important;
}

.buttonWithoutBackground {
  background: none !important;
  border: none !important;
}

.editFlex {
  justify-content: space-evenly !important;
}

/*Start Footer CSS*/

.wave {
  position: relative;
  top: 120px;
}

.main-footer {
  width: 100%;
  clip-path: ellipse(100% 57% at 52% 63%);
  background-color: #ef747c !important;
  background-image: url("../images/footer-bg.svg");
  background-position: bottom left;
  background-repeat: no-repeat;
  position: relative;
  z-index: 3;
  padding-top: 3em;
  padding-bottom: 1em;
}

.footer-logo {
  width: 192px;
}
.footer-col-1 {
  align-self: flex-start;
}
.footer-col-2 {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.lower-footer {
  background-color: #740d11 !important;
  width: 100% !important;
  /* height: 51px; */
  /* padding-bottom: 40px !important; */
  z-index: 6;
  position: relative;
  object-position: center;
  text-align: center;
  :a {
    text-decoration: none;
    font-size: 16px;
    font-weight: 500;
  }
}

.main-footer p {
  font-size: 17px;
  font-weight: 500;
  line-height: 1.53;
  color: #e6e6e6 !important;
}
.main-footer h6 {
  font-size: 15px;
  font-weight: bold;
  color: #e6e6e6 !important;
  text-align: rights !important;
}
.main-footer h5 {
  font-size: 19px;
  font-weight: bold;
  line-height: 1.37;
  color: #e6e6e6 !important;
}
.main-footer a {
  font-size: 17px;
  font-weight: 500;
  line-height: 1.53;
  color: #e6e6e6 !important;
  text-decoration: none;
}
.footer-list-1 {
  font-size: 14px !important;
  font-weight: 500;
  line-height: 1.53;
  padding: 0px;
  list-style: none;
  color: #e6e6e6 !important;
  text-decoration: none;
  & li::marker {
    content: "•";
    color: rgb(255, 255, 255);
    display: inline-block !important;
    width: 1em;
  }
}

.footer-list-2 {
  font-size: 15px !important;
  font-weight: 500;
  line-height: 1.53;
  padding: 0px;
  list-style: none;
  color: #e6e6e6 !important;
  text-decoration: none !important;
}

.social-media-account {
  display: flex;
  cursor: pointer;
  margin-top: 24px;
}

.sub-social {
  margin-left: 43px;
  width: 36px;
  height: 38px;
  text-align: center;
}

.footer-input-group {
  background-color: white;
  width: fit-content;
  border-radius: 28px;
  box-shadow: 0 10px 60px 0 rgba(38, 45, 118, 0.08);
  padding: 9.8px 24.1px 9.8px 11.1px;
  border: none;
  display: flex;
  :active {
    border: gray !important;
  }
  @media (max-width: 767px) {
    padding: 9.8px 1px 9.8px 11.1px;
    margin: 0 5px;
  }
}
.footer-sumbit {
  margin-left: 1em;
  /* width: 240px; */
  border: none;
  border-radius: 1em;
}
.paddingSides {
  padding-left: 2em;
  padding-right: 2em;
}

.footer-button {
  width: 128px;
  height: 54px;
  border-radius: 20px;
  background-color: #ab181e;
  color: white !important;
  border: none;
}

/*End Footer CSS*/
.backgroundChange {
  background-color: #b10000;
}

.fontSmall {
  font-size: 15px !important;
}

.product_one_modal_top .close_modal_icon {
  position: absolute;
  left: 0px;
  z-index: 10;
  top: -20px;
  cursor: pointer;
  width: 30px;
  height: 30px;
  text-align: center;
  line-height: 30px;
  font-size: 15px;
  border-radius: 50%;
  transition: 0.3s;
  display: inline-block;
  background: #b10000;
  color: white !important;
  border: 0;
}

.modal-content {
  border: none;
}

.modal.fade {
  display: block !important;
  opacity: 0;
  visibility: hidden;
  transition: all 0.3s ease 0s;
}

.modal.fade.show {
  display: block !important;
  opacity: 1;
  visibility: visible;
  transition: all 0.3s ease 0s;
}

.modal-content {
  border: none !important;
}

.product_one_modal_top .close_modal_icon:hover {
  background: #b10000;
  color: #000000 !important;
  transform: rotate(90deg);
}

.modal_product_content_one .reviews_rating {
  padding-top: 10px;
}

.modal_product_content_one {
  text-align: right;
}

.colorBlack {
  color: black !important;
}

.fontSize20 {
  font-size: 20px;
  font-weight: 600;
}

.fontSize25 {
  font-size: 25px;
  font-weight: 600;
}

.select__control {
  border: none !important;
  box-shadow: none !important;
}

.example {
  margin-bottom: 20px;
  margin-top: 20%;

  padding: 30px 50px;
  text-align: center;
  border-radius: 4px;
  min-height: 270px;
}

.ant-spin-dot-item {
  background-color: #b10000 !important;
}

.backgroundChangeGreen {
  background-color: #27a641 !important;
}

.colorIcon {
  color: #27a641 !important;
  fill: #27a641 !important;
}

.colorIconRed {
  color: #b10000 !important;
  fill: #b10000 !important;
}

.dropdown1 {
  z-index: 10000;
  position: relative;
}

.dropdown1,
.dropdown1 * {
  z-index: 10;
}

.dropdown1 .dropdown-btn1 {
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.dropdown-content1 {
  position: absolute;
  /* top: 110%; */
  right: 0;
  width: 97%;
  box-shadow: 0 0 10px 5px rgba(0, 0, 0, 0.07);
}

.dropdown-content1 .item1 {
  padding: 10px 20px;
  cursor: pointer;
  background-color: #1e1e1e;
}

.dropdown-content1 .item1:hover {
  background: #2d2d2d;
}

@media (max-width: 500px) {
  .ReactInputVerificationCode__item {
    margin: 0px 2px;
  }
}

.input-verification-code {
  display: flex;
  justify-content: center;
}

.red {
  color: #890405 !important;
}
.gray {
  color: #d9d9d9;
}

.ReactInputVerificationCode__item:focus-visible {
  outline: none;
  border: 1px solid #f79837;
}

/*payment new*/
.simplified-subscription-page__overlay {
  padding: 60px 0 98px;
  background-size: cover;
  background: linear-gradient(
      180deg,
      rgba(10, 141, 177, 0),
      rgba(0, 83, 107, 0.03) 14%,
      rgba(0, 58, 74, 0.27) 30%,
      rgba(0, 36, 45, 0.6) 53%,
      rgba(0, 14, 18, 0.88) 80%,
      #000
    ),
    url("../images/short-subscription-bg-dark.47910b5a246fa1add323.png"); /* Chrome 10-25, Safari 5.1-6 */
  background-size: cover;
  background-repeat: no-repeat;
  background-position: 50%;
}

.fontSize20NotBold {
  font-size: 22px;
  word-spacing: 5px;
}

.cardPlan {
  /*margin: 0px 10px;*/
  background-color: #000;
  border-radius: 10px;
}

.cardPlanSelected {
  outline: 2px solid #85cddc;
  cursor: not-allowed;
  /*margin: 0px 10px;*/
  background-color: #000;
  border-radius: 10px;
}

.yearlyPlanFirst {
  background-color: rgba(175, 224, 231, 0.6);
  width: 100%;
  height: 50px;
  border-top-right-radius: 10px;
  border-top-left-radius: 10px;
  padding: 10px 0px;
  font-size: 19px;
  text-align: center;
}

.monthlyPlanFirst {
  width: 100%;
  height: 50px;
  border-top-right-radius: 10px;
  border-top-left-radius: 10px;
  padding: 10px 0px;
  font-size: 19px;
  text-align: center;
  background-color: rgba(59, 66, 66, 0.5019607843137255);
}

.monthlyPlanSecond {
  background-color: rgba(59, 66, 66, 0.5019607843137255);
  width: 100%;
  /*border-radius: 10px;*/
  padding: 18px 0px;
  font-size: 21px;
  font-weight: bold;
  text-align: center;
}

.yearlyPlanSecond {
  background-color: rgba(10, 141, 177, 0.4);
  width: 100%;
  /*border-radius: 10px;*/
  padding: 18px 0px;
  font-size: 21px;
  font-weight: bold;
  text-align: center;
}

.yearlyMoney {
  padding: 10px 0px 0px 0px;
  font-size: 23px;
  font-weight: bold;
  color: #85cddc !important;
}

.yearlyMoneySpan {
  font-size: 21px;
  font-weight: normal;
}

.yearlyMoneyText {
  padding: 10px 0px 0px 0px;
  font-size: 19px;
}

.yearlyMoneyText1 {
  padding: 10px 0px 0px 0px;
  font-size: 19px;
  color: #85cddc !important;
}

.btnYearly {
  background-color: #3b4242 !important;
  border: none !important;
  padding: 0.675rem 3.75rem;
  font-size: 19px;
}

.btnYearly:focus {
  box-shadow: none !important;
}

.displayFlexSmall {
  flex-direction: row !important;
}

.marginCard {
  margin-left: 1.5rem;
}

.marginCardLeft {
  margin-right: 1.5rem;
}

@media (max-width: 800px) {
  .displayFlexSmall {
    flex-direction: column !important;
  }

  .marginCard {
    margin-left: 0rem;
    margin-bottom: 1rem;
    margin-top: 1.5rem;
  }

  .marginCardLeft {
    margin-right: 0rem;
    margin-bottom: 1rem;
  }

  .cardPlanLeft {
    margin-top: 25px;
  }
}

.discountPlan {
  color: #d4d4d4 !important;
  font-size: 20px;
  word-spacing: 3px;
}

.inputDiscount {
  background-color: #3b4242 !important;
  color: #a1a1a1 !important;
  border: none !important;
  padding: 0.575rem 0.75rem;
  border-right: 4px;
}

.inputDiscount:focus {
  box-shadow: none !important;
}

.btnNext {
  background-color: #eb2027;
  font-size: 20px;
}

.btnDiscount {
  border-color: rgba(238, 238, 238, 0.54);
  color: rgba(238, 238, 238, 0.7) !important;
  padding: 0.475rem 1.75rem !important;
}

.cardPlanLeftOverlay {
  opacity: 0.4;
  background-color: #000;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  border-radius: 10px;
  z-index: 1;
}

.cardPlanLeft {
  position: relative;
  width: 100%;
  height: fit-content;
  border-radius: 10px;
  padding: 40px 44px 40px 40px;
}

.cardPlanNotOverlay {
  width: 100%;
  z-index: 2;
  position: relative;
}

.listPlans {
  padding-top: 18px;
  border-top: 1px solid #5d6666;
  text-align: right;
  display: flex;
  align-items: center;
  border-bottom: 1px solid #5d6666;
  padding-bottom: 18px;
  font-size: 20px;
}

.listPlansTwo {
  padding-top: 18px;
  text-align: right;
  display: flex;
  align-items: center;
  border-bottom: 1px solid #5d6666;
  padding-bottom: 18px;
  font-size: 20px;
}

.listPlansParagraph {
  color: #d4d4d4 !important;
}

.visaPlan {
  color: #a1a1a1 !important;
}

.visaItem {
  padding: 18px 11px;
  border-radius: 10px;
  background-color: #000;
  display: flex;
  align-items: center;
  width: 90px;
  height: 53px;
}

.visaImage {
  width: 100%;
  height: 26px;
  object-fit: contain;
}

.planPrivacy {
  color: #afe0e7 !important;
}

.planPrivacy2 {
  color: #afe0e7 !important;
  text-decoration: underline;
}

#box {
  position: relative;
}

#icon {
  color: white;
  position: absolute;
  right: -30px;
  top: 0px;
  cursor: pointer;
}

#icon1 {
  color: white;
  position: absolute;
  right: -30px;
  top: 40px;
  cursor: pointer;
}

.custom-ui {
  background-color: rgb(255, 255, 255);
  padding: 30px 140px;
  border-radius: 14px;
}

.react-confirm-alert-overlay {
  /*background-color: ;*/
  background: rgba(255, 255, 255, 0.7) !important;
}

.doneWhite {
  color: white !important;
  font-size: 12px;
  margin-top: 5px;
}
.cat-card {
  padding: 30px 35px 30px 35px !important;
  border-radius: 23px !important;
  border: 1px solid #f0b2b3 !important;
  height: 212px;
  margin-bottom: 20px;
}

.sloped-text {
  position: absolute;
  top: 15px;
  left: 10px;
  transform: rotate(-45deg);
  color: #890405;
}
.cat-card h3 {
  font-size: 25px;
}
.cat-card-btn {
  background: #890405;
  color: white !important;
  gap: -12px;
  padding: 30px 35px 30px 35px !important;
  border-radius: 23px !important;
  border: 1px solid #f0b2b3 !important;
  height: 212px;
}
.cat-card-btn h3 {
  color: white;
  font-size: 58px;
}
.cat-card-btn p {
  color: white;
  font-size: 15px;
}
.cat-card-btn span {
  color: white;
  font-size: 45px;
  line-height: 34px;
}

.subject-input {
  background-color: white !important;
  color: red;
}

.teacher-btn {
  color: #890405;
  border-radius: 20px;
  padding: 10px 20px;
  background-color: white;
  width: 100%;
}
.teacher-btn:hover {
  background: #890405;
  color: white;
}
.teacher-btn:focus {
  background: #890405 !important;
  color: white !important;
}

.my-dropdown {
  position: relative;
  display: inline-block;
  border: none;
  outline: none;
}

.my-dropdown-content {
  display: none;
  position: absolute;
  background-color: #ffffff;
  min-width: 160px;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 1;
}
.my-dropdown-content a {
  color: black;
  padding: 12px 16px;
  text-decoration: none;
  display: block;
}
.my-dropdown-content a :hover {
  background-color: rgb(243, 243, 243);
}
.my-dropdown:hover .my-dropdown-content {
  display: block;
}
.singleCourseCard {
  width: 25%;
}

.courseCard {
  width: 100%;
  height: fit-content;
  border-bottom-left-radius: 89px;
  border-bottom-right-radius: 89px;
  background-image: url("../images/course-bg.png");
  box-shadow: inset 0 0 0 2000px rgba(245, 230, 231, 0.8);
  background-repeat: no-repeat;
  color: #434343;
  padding: 50px;
}
.courseCard h1 {
  font-size: 5em;
  font-weight: 700;
  letter-spacing: 0.02em;
  text-align: center;
  color: #b71014;
  margin: 0px;
  @media (max-width: 767px) {
    font-size: 3em;
  }
}

.courseCard-text {
  @media (max-width: 767px) {
    font-size: 1em;
  }
}
.course-section {
  background-color: rgba(255, 209, 209, 1);
  color: rgba(0, 0, 0, 1);
  display: flex;
  justify-content: space-between;
  font-size: 20px;
  font-weight: 500;
  line-height: 35px;
  letter-spacing: 0.02em;
  text-align: right;
  margin: 5px;
  padding: 5px 15px;
  border-radius: 13px;
  &:hover {
    background: rgba(255, 240, 240, 1);
  }
}

.pop-up {
  margin-top: -201px;
  @media (max-width: 767px) {
    margin: 0;
    padding: 3em;
  }
}

.mob-col-reverse {
  @media (max-width: 767px) {
    flex-direction: column-reverse;
  }
}
.margin-right {
  margin-right: 10px;
}
.d-contents {
  display: contents;
}

.row {
  margin-right: 0px;
  margin-left: 0px;
}

.container-fluid {
  padding: 0px;
}

.font-bolder {
  font-weight: bolder;
}
a {
  text-decoration: none !important;
  cursor: pointer !important;
}
